<template>
    <list-page
        page-title="Bank Accounts"
        page-icon="mdi-folder-outline"
        collection-name="bank"
        :items-per-page="20"
        add-dialog-title="Add Bank Account"
        is-filter
    />
</template>

<script>
import ListPage from '../../../components/pages/List';

export default {
    name: 'BankAccountList',
    components: { ListPage },
};
</script>

<style scoped></style>
